import { Button, Typography } from "@material-ui/core";
import css from "./App.module.scss";
import React, { Component } from "react";

class FileUpload extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.onUpdatedFiles = this.onUpdatedFiles.bind(this);
    this._formInput = React.createRef();
    this.state = {
      uploadedFileSrc: "",
    };
  }

  onUpdatedFiles() {
    // ファイルがなければもどる
    if (!this._formInput.current.files.length) {
      return;
    }

    const fileType = this._formInput.current.files[0].type;
    if (fileType !== `image/jpeg`) {
      alert(`Please input JPEG File / JPEG 画像を入れてください`);
    }

    var imElement = new Image();
    let blob = this._formInput.current.files[0];
    let imageUrl = window.URL.createObjectURL(blob);

    let that = this;

    imElement.onload = function () {
      var width = imElement.naturalWidth;
      var height = imElement.naturalHeight;

      let longSide = Math.max(width, height);
      let shortSide = Math.min(width, height);

      // 3:4 かつ縦位置のみ
      if (longSide / shortSide !== 2400 / 1800 || width > height) {
        window.alert(
          `画像の比率が 3:4 ではないようです。お手数ですがリサイズをお願いします🙇 / The image ratio does not seem to be 3:4. Please resize the image.️`
        );
        // 読み込みをキャンセル
        that.setState((state) => ({
          uploadedFileSrc: null,
        }));
        that.props.onUplaoded(null);
        that._formInput.current.files = null;
        console.log(`that._formInput:`, that._formInput);
      }
    };

    imElement.src = imageUrl;
    this.setState((state) => ({
      uploadedFileSrc: imageUrl,
    }));
    this.props.onUplaoded(imageUrl);
  }

  /**
   *
   * @returns {string|string}
   */
  getUploadedFileSrc() {
    return this.state.uploadedFileSrc;
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Import your table/wall-image
        </Typography>
        <Typography variant="caption" paragraph>
          3:4 format, 1800px:2400px, JPEG sRGB
        </Typography>

        <div>
          <input
            accept="image/jpeg"
            className={css.input}
            id="contained-button-file"
            type="file"
            onChange={this.onUpdatedFiles}
            ref={this._formInput}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </div>
      </div>
    );
  }
}

export default FileUpload;
