class Rectangle {
  constructor(x = 0, y = 0, width = 0, height = 0) {
    this._x = x;
    this._y = y;
    this._width = width;
    this._height = height;
  }

  get x() {
    return this._x;
  }

  get y() {
    return this._y;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  setWidth(width_) {
    this._width = width_;
  }

  setHeight(height_) {
    this._height = height_;
  }
}

export default Rectangle;
