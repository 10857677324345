import React, { Component } from "react";
import { addTag } from "../actions";
import Rectangle from "./utils/Rectangle";
import {connect} from "react-redux"
import {Button} from "@material-ui/core"

class RestoreButton extends Component {
  constructor(prop) {
    super(prop);
    this.doRestore = this.doRestore.bind(this);
  }

  doRestore() {
    const code = window.prompt("復旧したいコードを入力してください", "");
    const tagJson = JSON.parse(code);
    for (let i = 0; i < tagJson.length; i += 1) {
      let tag = tagJson[i];
      this.props.dispatch(
        addTag(
          tag.title,
          tag.text,
          tag.price,
          tag.linkTo,
          new Rectangle(
            tag.rectangle.x,
            tag.rectangle.y,
            tag.rectangle.width,
            tag.rectangle.height
          )
        )
      );
    }
  }

  render() {
    return (
      <Button variant="contained" color="inherit" component="span" onClick={this.doRestore}>コードから復旧する / Restore from a code</Button>
    );
  }
}

export default connect()(RestoreButton);
