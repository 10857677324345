const tags = (state = [], action) => {
  switch (action.type) {
    case "ADD_TAG":
      return [
        ...state,
        {
          id: action.id,
          text: action.text,
          title: action.title,
          price: action.price,
          linkTo: action.linkTo,
          rectangle: {
            x: action.rectangle.x,
            y: action.rectangle.y,
            width: action.rectangle.width,
            height: action.rectangle.height,
          },
        },
      ];
    case "EDIT_TAG":
      // 削除処理のために配列をコピー
      let editResult = state.concat();
      for (let i = 0; i < editResult.length; i += 1) {
        // 該当のタグを削除する
        if (action.id === editResult[i].id) {
          editResult[i].text = action.text;
          editResult[i].title = action.title;
          editResult[i].price = action.price;
          editResult[i].linkTo = action.linkTo;
          editResult[i].rectangle = action.rectangle;
        }
      }
      // 削除したものをリターン
      return editResult;

    case "DELETE_TAG":
      // 削除処理のために配列をコピー
      let result = state.concat();
      for (let i = 0; i < result.length; i += 1) {
        // 該当のタグを削除する
        if (action.id === result[i].id) {
          result.splice(i, 1);
        }
      }
      // 削除したものをリターン
      return result;

    default:
      return state;
  }
};

export default tags;
