import { connect } from "react-redux";
import { deleteTag } from "../actions";
import TaggingImage from "../components/TaggingImage";
import { VisibilityFilters } from "../actions";

const getVisibleTags = (tags, filter) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return tags;
    default:
      throw new Error("Unknown filter: " + filter);
  }
};

const mapStateToProps = (state) => ({
  tags: getVisibleTags(state.tags, state.visibilityFilter),
});

const mapDispatchToProps = (dispatch) => ({
  deleteTag: (id) => dispatch(deleteTag(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaggingImage);
