import { connect } from "react-redux";
import { deleteTag, editTag } from "../actions";
import TagList from "../components/TagList";
import { VisibilityFilters } from "../actions";

const getVisibleTags = (tags, filter) => {
  switch (filter) {
    case VisibilityFilters.SHOW_ALL:
      return tags;
    default:
      throw new Error("Unknown filter: " + filter);
  }
};

const mapStateToProps = (state) => ({
  tags: getVisibleTags(state.tags, state.visibilityFilter),
});

const mapDispatchToProps = (dispatch) => ({
  deleteTag: (id) => dispatch(deleteTag(id)),
  editTag: (id, title, description, price, url, rect) =>
    dispatch(editTag(id, title, description, price, url, rect)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
