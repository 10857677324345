import { TextField, Typography } from "@material-ui/core";
import css from "./App.module.scss";
import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

class OutputCode extends Component {
  constructor(props) {
    super(props);
    console.log(`OutputCode props`, props);
  }

  render() {
    let output = "";
    if (this.props.tags.length > 0) {
      output = JSON.stringify(this.props.tags);
    }



    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Your table/wall code
        </Typography>
        <div>
          <TextField
            className={css.output}
            id="outlined-multiline-static"
            label=""
            multiline
            rows={4}
            value={output}
            variant="outlined"
            size={`small`}
            classes={{root: css.embedOutputField}}
          />
          <Typography variant={`caption`}>
            <p>
              出店者管理ページに、画像と上記のコードを登録して下さい。
            </p>
            <p>
              Please input your image and the code above into the exhibitor console.
            </p>
            <p style={{color:"#999"}}>Ver. 1.0.0</p>
          </Typography>
        </div>
      </div>
    );
  }
}

OutputCode.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default connect()(OutputCode);
