import React, { Component } from "react";
import Rectangle from "./utils/Rectangle";
import { addTag } from "../actions";

import css from "./TaggingImage.module.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TaggingModal from "./TaggingModal";
import VisibleTagList from "../containers/VisibleTagList";

class TaggingImage extends Component {
  constructor(props) {
    super(props);
    this._doMouseDown = this._doMouseDown.bind(this);
    this._doDrag = this._doDrag.bind(this);
    this._doMouseUp = this._doMouseUp.bind(this);
    this.state = {
      mousedown: false,
      mousedownPoint: { x: 0, y: 0 },
      activeRect: null,
    };
    this.onCompleteTagging = this.onCompleteTagging.bind(this);
    this.onCanceledTagging = this.onCanceledTagging.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._doMouseDown, false);
    document.addEventListener("mousemove", this._doDrag, false);
    document.addEventListener("mouseup", this._doMouseUp, false);
  }

  componentDidUnMount() {
    document.removeEventListener("mousedown", this._doMouseDown);
    document.removeEventListener("mousemove", this._doDrag);
    document.removeEventListener("mouseup", this._doMouseUp);
  }

  /**
   *
   * @param e
   * @returns {boolean}
   * @private
   */
  _doMouseDown(e) {
    if (!e.target.classList.contains(`js-target-image`)) {
      return false;
    }

    const imageRect = e.target.getBoundingClientRect();
    const mouseXonImage = e.clientX - imageRect.x;
    const mouseYonImage = e.clientY - imageRect.y;
    this.setState((state) => ({
      mousedown: true,
      mousedownPoint: { x: mouseXonImage, y: mouseYonImage },
      activeRect: new Rectangle(mouseXonImage, mouseYonImage, 0, 0),
    }));
  }

  /**
   *
   * @param e
   * @returns {boolean}
   * @private
   */
  _doDrag(e) {
    if (!this.state.mousedown) {
      return false;
    }
    const imageRect = e.target.getBoundingClientRect();
    const mouseXonImage = e.clientX - imageRect.x;
    const mouseYonImage = e.clientY - imageRect.y;

    let startX = this.state.mousedownPoint.x;
    let endX = mouseXonImage;
    let startY = this.state.mousedownPoint.y;
    let endY = mouseYonImage;
    if (mouseXonImage < this.state.mousedownPoint.x) {
      startX = mouseXonImage;
      endX = this.state.mousedownPoint.x;
    }
    if (mouseYonImage < this.state.mousedownPoint.y) {
      startY = mouseYonImage;
      endY = this.state.mousedownPoint.y;
    }

    // ％ の値に変換
    const startPercentX = (startX / imageRect.width) * 100;
    const startPercentY = (startY / imageRect.height) * 100;
    const percentWidth = ((endX - startX) / imageRect.width) * 100;
    const percentHeight = ((endY - startY) / imageRect.height) * 100;

    this.setState((state) => ({
      activeRect: new Rectangle(
        startPercentX,
        startPercentY,
        percentWidth,
        percentHeight
      ),
    }));
  }

  /**
   *
   * @param e
   * @returns {boolean}
   * @private
   */
  _doMouseUp(e) {
    if (!this.state.mousedown) {
      return false;
    }

    // 面積がある程度大きければ登録する
    if (this.state.activeRect.width > 1 && this.state.activeRect.height > 1) {
      this.setState((state) => ({
        isTaggingModalOpen: true,
      }));
    }
    this.setState((state) => ({
      mousedown: false,
    }));
  }

  /**
   * タグ付けモーダルが完了した
   */
  onCompleteTagging(values) {
    console.log(`onCompleteTagging`, values);

    console.log(`this.props.`, this.props)
    this.props.dispatch(
      addTag(
        values.title,
        values.description,
        values.price,
        values.url,
        new Rectangle(
          this.state.activeRect.x,
          this.state.activeRect.y,
          this.state.activeRect.width,
          this.state.activeRect.height
        )
      )
    );

    this.setState((state) => ({
      activeRect: null,
      isTaggingModalOpen: false,
    }));
  }



  /**
   * タグ付けモーダルがキャンセルされた
   *
   */
  onCanceledTagging() {
    this.setState((state) => ({
      activeRect: null,
      isTaggingModalOpen: false,
    }));
  }

  /**
   *
   * @returns {*}
   */
  render() {
    let elActiveRect = "";
    if (this.state.activeRect) {
      elActiveRect = (
        <div
          className={css.activeRect}
          style={{
            top: `${this.state.activeRect.y}%`,
            left: `${this.state.activeRect.x}%`,
            height: `${this.state.activeRect.height}%`,
            width: `${this.state.activeRect.width}%`,
          }}
        />
      );
    }

    return (
      <div className={css.base}>
        {this.state.isTaggingModalOpen && (
          <TaggingModal
            onComplete={this.onCompleteTagging}
            onCanceled={this.onCanceledTagging}
          />
        )}

        {elActiveRect}
        <VisibleTagList />
        <div className={css.hitArea + ` js-target-image`} />

      </div>
    );
  }
}

TaggingImage.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default connect()(TaggingImage);
