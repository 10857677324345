import React, { Component } from "react";
import PropTypes from "prop-types";
import css from "./TagList.module.css";
import TaggingModal from "./TaggingModal";

class TagList extends Component {
  constructor() {
    super();

    this.state = {
      isEditMode: false,
      editTarget: false,
    };
    this._editTag = this._editTag.bind(this);
    this._onEditComplete = this._onEditComplete.bind(this);
    this._onEditCancel = this._onEditCancel.bind(this);
    this._onEditDelete = this._onEditDelete.bind(this);
  }

  _editTag(e) {
    const dataId = e.target.dataset.id;
    for (let i = 0; i < this.props.tags.length; i += 1) {
      if (Number(this.props.tags[i].id) === Number(dataId)) {
        this.setState((state) => ({
          editTarget: this.props.tags[i],
          isEditMode: true,
        }));
        break;
      }
    }
  }

  _onEditComplete(values) {
    // rect の情報を結合
    let rect = null;
    const tags = this.props.tags;
    for (let i = 0; i < tags.length; i += 1) {
      if (Number(tags[i].id) === Number(this.state.editTarget.id)) {
        rect = tags[i].rectangle;
      }
    }

    this.props.editTag(
      this.state.editTarget.id,
      values.title,
      values.description,
      values.price,
      values.url,
      rect
    );

    this.setState((state) => ({
      isEditMode: false,
      editTarget: null,
    }));
  }

  _onEditCancel() {
    this.setState((state) => ({
      isEditMode: false,
      editTarget: null,
    }));
  }

  _onEditDelete() {

    const okGo = window.confirm(
      "本当に削除してよろしいですか？ / Are you sure you want to delete it?"
    );

    if (okGo) {
      this.props.deleteTag(this.state.editTarget.id);
      this.setState((state) => ({
        isEditMode: false,
        editTarget: null,
      }));
    }
  }

  render() {
    const tags = this.props.tags;

    return (
      <div>
        {this.state.isEditMode && (
          <TaggingModal
            onComplete={this._onEditComplete}
            onCanceled={this._onEditCancel}
            onDeleted={this._onEditDelete}
            editTarget={this.state.editTarget}
          />
        )}

        {tags.map((tag) => (
          <button
            className={css.recordedRect}
            key={tag.id}
            style={{
              top: `${tag.rectangle.y}%`,
              left: `${tag.rectangle.x}%`,
              height: `${tag.rectangle.height}%`,
              width: `${tag.rectangle.width}%`,
            }}
            data-id={tag.id}
            // onClick={() => deleteTag(tag.id)}
            onClick={this._editTag}
          />
        ))}
      </div>
    );
  }
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  deleteTag: PropTypes.func.isRequired,
  editTag: PropTypes.func.isRequired,
};

export default TagList;
