let nextTagId = 0;
export const addTag = (title, text, price, linkTo, rectangle) => ({
  type: "ADD_TAG",
  id: nextTagId++,
  title,
  text,
  price,
  linkTo,
  rectangle,
});

export const setVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const deleteTag = (id) => ({
  type: "DELETE_TAG",
  id,
});

export const editTag = (id, title, text, price, linkTo, rectangle) => ({
  type: "EDIT_TAG",
  id,
  title,
  text,
  price,
  linkTo,
  rectangle,
});

export const VisibilityFilters = {
  SHOW_ALL: "SHOW_ALL",
  SHOW_ACTIVE: "SHOW_ACTIVE",
};
