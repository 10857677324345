import React, { Component } from "react";
import { Container, FormControl, FormHelperText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import css from "./TaggingModal.module.css";

export default class TaggingModal extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    console.log(`props.editTarget`, props.editTarget);

    if (props.editTarget) {
      this.state = {
        theUrl: props.editTarget.linkTo,
        title: props.editTarget.title,
        price: props.editTarget.price,
        description: props.editTarget.text,
        isEditMode: true,
      };
    } else {
      this.state = {
        theUrl: "",
        title: "",
        price: "",
        description: "",
      };
    }
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleUrlFieldChange = this.handleUrlFieldChange.bind(this);
    this.handleTitleFieldChange = this.handleTitleFieldChange.bind(this);
    this.handlePriceFieldChange = this.handlePriceFieldChange.bind(this);
    this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
    this.handleDescriptionFieldChange = this.handleDescriptionFieldChange.bind(
      this
    );
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isURL", (value) => {
      const isURL = new RegExp(
        "https?://([\\w-]+\\.)+[\\w-]+(/[\\w- .?%&=]*)?"
      );
      return value.match(isURL);
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isURL");
  }

  handleUrlFieldChange(event) {
    const theUrl = event.target.value;
    this.setState({ theUrl });
  }
  handleTitleFieldChange(event) {
    const title = event.target.value;
    this.setState({ title });
  }
  handlePriceFieldChange(event) {
    const price = event.target.value;
    this.setState({ price });
  }
  handleDescriptionFieldChange(event) {
    const description = event.target.value;
    this.setState({ description });
  }

  handleCancelClicked(event) {
    this.props.onCanceled();
  }

  handleDeleteClicked(event) {
    this.props.onDeleted && this.props.onDeleted();
  }

  /**
   *
   * @param e
   */
  handleSubmit = (e) => {
    const title = document.getElementById(`book-title`).value;
    const price = document.getElementById(`book-price`).value;
    const url = document.getElementById(`book-url`).value;
    const description = document.getElementById(`book-description`).value;

    this.props.onComplete({
      title: title,
      price: price,
      url: url,
      description: description,
    });
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const { theUrl, title, price, description } = this.state;

    console.log(this.state);

    return (
      <div className={css.base}>
        <Container maxWidth={"md"}>
          <Paper className={css.basePaper}>
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={(errors) => console.log(errors)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextValidator
                      label="Title of the book / 本のタイトル"
                      fullWidth
                      onChange={this.handleTitleFieldChange}
                      name={`book-title`}
                      id={`book-title`}
                      value={title}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      aria-describedby="book-title-helper"
                    />
                    <FormHelperText id="book-title-helper">
                      日本語と英語を併記すると海外の方にも伝わりやすいです
                      <br /> Writing both Japanese and English together will
                      make it easier to communicate with people in Japan.
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextValidator
                      label="Price of the item / 商品価格"
                      onChange={this.handlePriceFieldChange}
                      fullWidth
                      name={`book-price`}
                      id={`book-price`}
                      value={price}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      aria-describedby="price-helper"
                    />
                    <FormHelperText id="price-helper">
                      e.g. 1,200 yen + tax
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextValidator
                      label="Link URL / リンク先アドレス"
                      onChange={this.handleUrlFieldChange}
                      fullWidth
                      name="book-url"
                      id={`book-url`}
                      value={theUrl}
                      validators={["required", "isURL"]}
                      errorMessages={[
                        "This field is required",
                        "URL is not valid",
                      ]}
                      aria-describedby="book-url-helper"
                    />
                    <FormHelperText id="book-url-helper">
                      e.g. https://www.example.com/book/1/
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextValidator
                      label="Description / 商品説明"
                      fullWidth
                      multiline
                      onChange={this.handleDescriptionFieldChange}
                      value={description}
                      name={`book-description`}
                      id={`book-description`}
                      variant={`outlined`}
                      rows={8}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: `1em` }}
                    type="submit"
                  >
                    OK!
                  </Button>
                  <Button
                    variant="contained"
                    onClick={this.handleCancelClicked}
                  >
                    Cancel
                  </Button>

                  {this.state.isEditMode && (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: `3em` }}
                      onClick={this.handleDeleteClicked}
                    >
                      Delete
                    </Button>
                  )}


                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
        </Container>
      </div>
    );
  }
}
