import { Container, Paper, Grid, Box } from "@material-ui/core";
import React, { Component } from "react";
import VisibleTaggingImage from "../containers/VisibleTaggingImage";
import css from "./App.module.scss";
import FileUpload from "./UploadFile";
import VisibleOutputCode from "../containers/VisibleOutputCode";
import VisibleRestoreButton from "../containers/VisibleRestoreButton";

class App extends Component {
  /**
   *
   */
  constructor() {
    super();
    this.handleUploaded = this.handleUploaded.bind(this);
    this.state = {
      imageUrl: "",
    };
  }

  /**
   * ファイルの選択が完了した
   */
  handleUploaded(imageUrl) {
    console.log(imageUrl);

    this.setState((state) => ({
      imageUrl: imageUrl,
    }));
  }

  render() {
    return (
      <div>
        <Box m={2}>
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <div className={css.imgBox}>
                  {this.state.imageUrl ? (
                    <div className={css.imgBox__area}>
                      <div className={css.imgBox__area__hittest}>
                        <VisibleTaggingImage />
                      </div>
                      <img src={this.state.imageUrl} alt="" />
                    </div>
                  ) : (
                    <div className={css.imgBox__pseudoArea} />
                  )}
                </div>
              </Grid>
              <Grid item xs={3} style={{
                position: "relative",
              }}>
                <Paper style={{
                  position: "sticky",
                  top: "10px"
                }}>
                  <Box
                    p={2}

                  >
                    <Box mb={4}>
                      <FileUpload onUplaoded={this.handleUploaded} />
                    </Box>
                    {this.state.imageUrl && (
                      <Box mb={4}>
                        <VisibleRestoreButton />
                      </Box>
                    )}
                    <Box mb={4}>
                      <VisibleOutputCode />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    );
  }
}

export default App;
